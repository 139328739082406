#specials {
  display: none;
}

#special-popdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  background: var(--page-bg-color);
  *, *:before, *:after {
    box-sizing: inherit;
    color: var(--text-color);
  }

  #specials-inner {
    width: 100%;
    max-width: unset;
    padding: 25px 50px 0;
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }

  #specials-inner .special {
    text-align: center;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    h3 {
      font-size: 2.0rem;
      margin: 0 0 10px;
      color: var(--heading-color);
      padding-bottom: 0;
    }
    p {
      font-size: 1.6rem;
      text-align: center;
    }
  }

  .special-image {
    max-width: 350px;
    padding: 0;
    img {
      width: 100%;
      height: auto;
      max-height: 150px;
      object-fit: contain;
      object-position: center;
    }
  }


  #special-popdown-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    height: 24px;
    width: 24px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      fill: var(--text-color);
      transition: all 0.3s ease;
    }

    &:hover, &:focus {
      svg {
        opacity: 0.4;
      }
    }
  }


  &.special-popdown--show {
    max-height: 600px;
  }
  &.special-popdown--hiding {
    max-height: 0;
  }
  &.special-popdown--hidden {
    max-height: 0;
    display: none;
  }

  .swiper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-pagination {
    bottom: 5px;
  }

  .swiper-pagination-bullet {
    background: #b9b9b9;
  }
  .swiper-pagination-bullet-active {
    background: #9e9e9e;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 768px) {
    #specials-inner {
      flex-direction: row-reverse;
      justify-content: center;
    }
    #specials-inner .special  {
      width: unset;
      * {
        text-align: left;
      }
    }
    .swiper {
      padding-bottom: 20px;
    }
    .swiper-slide {
      flex-direction: row;
    }
    #specials-inner {
      .special-image + .special {
        align-items: start;
        p {
          text-align: left;
        }
      }
    }

    .special-image {
      img {
        max-height: 200px;
      }
    }
  }
}

