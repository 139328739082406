.block.footer{
	position: relative;
	background: #060606;
	color: #fff;
	padding: 25px;


	h1,h2,h3,h4,h5,h6{
		font-size: 2.8rem;
		padding: 25px 0 10px 0;
		margin:0;
		font-family: var(--heading-font);
	}
	
	li{
		list-style: none;
	}

	a{
		color: #fff;

		&:hover{
			color: @button-bg;
		}
	}

	.soc{
		display: flex;
		padding-bottom: 25px;
		li{
			padding-right: 20px;
		}

		a{

		}

		svg{
			height: 30px;
			width: 30px;
		}
	}

	.misc-logo{
		height: 20px;
		width: 20px;
		margin: 0 4px;
		display: inline;
	}

	.powered-by{
		padding-top: 15px;
	}

	.inner + .inner{
		padding-top: 25px;
		border-top: 1px solid #fff;
	}

.tablet({

	.inner{
		display: flex;
		padding-bottom: 60px;

		+ .inner{
			padding-bottom: 0;
			display: block;
			overflow: hidden;

			p{
				float: left;

				+ p{
					float: right;
					padding-top: 0;
				}
			}
		}
	}

	.column{
		width: 33%;
	}
});

.desktop({
	
});

}