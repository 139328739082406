.block.jump{
	position: relative;
	padding: 15px;
	.inner {
		max-width: 1400px !important;
	}
    .item{
        position: relative;
        display: block;

        font-size: 2rem;

        strong{
            font-size: 2.5rem;
        }
    }

    a{
        display: block;
    }

    .label{
				pointer-events: none;
        position: absolute;
        bottom: 0;
        right:0;
        line-height: 20px;
        padding: 15px;
        background: #000;
				* {
					color: #fff;
				}
    }

    .item + .item{
        margin-top: 30px;
    }

.tablet({
	.inner{
		display: flex;
		justify-content: space-between;
	}

	.item{
		width: 60%;
		margin:0 !important;
		padding:0;

		+ .item{
			width: 38%;
		}

		img{
			min-height: 100%;
			object-fit: cover;
			width: auto;
			min-width: 100%;
		}

		a{
			display: block;
			height: 100%;
		}
	}

	.label{
		padding: 20px 30px;

		strong{
			font-size: 4rem;
			display: block;
			line-height: 1.2;
		}
	}

	&.wide{
		.item{
			width: 100%;
		}
	}

});

.desktop({

});

}

#rs-builder {
	.block.jump{
		.label {
			pointer-events: all;
		}
	}
}