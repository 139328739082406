.generic-supernav-overlay{
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index: 1000;
	backdrop-filter: blur(3px);

	&:before{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content:"";
		background: #000;
		opacity: .85;
	}

	.inner {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		justify-content: space-between;
	}

	.primary{
		padding: 10px 20px 20px;

		a{
			color: #fff;
			display: flex;
			font-size: 1.7rem;
			line-height: 1.75;
			&:hover{
				background: var(--button-bg-color);
			}
		}
	}


	.secondary{
		padding: 15px 20px 0;
		color: #fff;
	}


	.generic-social{
		padding-bottom: 20px;

		li{
			list-style: none;
			display: inline-block;
			padding-right: 20px;
		}

		a{
			color: #fff;
			font-size: 24px;
			display: block;
			height: 53px;
			line-height: 53px;
			width: 53px;
			border-radius: 100%;
			background: @primary-bg;
			text-align: center;
			padding: 1px;

			&:hover{
				background: #fff;
				color: @text;
			}
		}

		svg{
			display: block;
			height: 25px;
			width: 25px;
			margin: auto;
			margin-top: 14px;
		}

		span{
			display: none;
		}
	}


	.generic-contact{
		padding: 20px;
		font-size: 1.4rem;
		line-height: 1.35;

		strong{
			font-size: 1.6rem;
			font-weight: bold;
			padding-bottom: 10px;
			display: block;
		}

		a{
			color: @text;

			&:hover{
				color: @primary-bg;
			}
		}

		p + p{
			padding-top: 10px;
		}
	}


	.generic-logo{
		display: none;

		img{
			max-width: 90%;
			max-height: 63px;
		}
	}

.tablet({
	display: flex;

	.inner{
		height: 100%;
		display: flex;
		flex-direction: row;
	}

	.primary{
		width: 60%;
		display: flex;
		align-items: center;
		font-size: 3rem;
	}

	.secondary{
		position: relative;
		width: 40%;
		background: #fff;
		display: flex;
		align-items: center;
		color: #111;

		// &:before{
		// 	position: absolute;
		// 	top:0;
		// 	left: 0;
		// 	right: -10000px;
		// 	bottom:0;
		// 	content:"";
		// 	background: #fff;
		// }

		a{
			color: #111;
		}
	}

	.generic-logo{
		display: block;
	}

	.in{
		position: relative;
		width: 100%;
	}

	.generic-social{
		padding: 30px 0;
		ul{
			border-top: 1px solid var(--button-bg-color);
			border-bottom: 1px solid var(--button-bg-color);
		}

		li{
			padding: 30px 0 30px 0;
			width: 33%;
		}

		a{
			position: relative;
			background: var(--button-bg-color);
			svg{
				color: #fff;
			}

			span{
				display: block;
				height: 55px;
				line-height: 55px;
				left: 55px;
				position: absolute;
				top:0;
				font-size: 18px;
				padding-left: 8px;
			}

			&:hover{
				background: #fff;
				svg{
					color: var(--button-bg-color);
				}
			}
		}

		// li{
		// 	display: block;
		// 	padding-bottom: 10px;
		// 	overflow: hidden;
		// }

		// a{
		// 	float: left;
		// }

		// span{
		// 	height: 55px;
		// 	line-height: 55px;
		// 	padding-left: 10px;
		// 	color: @primary;
		// }
	}

	.generic-contact{
		background: none;
		padding: 0;
		a {
			font-size: 1.7rem;
			line-height: 1.75;
		}
	}
});
}


@media screen and (max-width: 767px){
	.generic-supernav-overlay .inner{
		height: 100%;
	}

	.generic-supernav-overlay{
		padding-top: 8rem;
	}

	.secondary{
		// position: absolute;
		// bottom: 0;
		// left:0;
		// right:0;
		color: #111 !important;
		background: #fff;
	}

	.generic-contact{
		background: none;
		padding: 0 0 20px 0 !important;
	}

	.in li{
		padding-top: 15px;
	}

	.generic-social{
		padding:  0 0 30px 0 !important;
		ul{
			border-top: 1px solid var(--button-bg-color);
			border-bottom: 1px solid var(--button-bg-color);
		}

		li{
			padding: 10px 0 0 0 !important;
			width: 45%;
		}

		a{
			position: relative;
			background: var(--button-bg-color) !important;
			svg{
				color: #fff;
			}

			span{
				display: block !important;
				height: 55px;
				line-height: 55px;
				left: 55px;
				position: absolute;
				top:0;
				font-size: 18px;
				padding-left: 8px;
				color:  var(--button-bg-color) !important;
				font-size: 1.5rem;
			}

			&:hover{
				background: #fff;
				svg{
					color: var(--button-bg-color);
				}
			}
		}


	}
}