.block.text{
	position: relative;
	padding: 15px;

	p,ul,ol,dl{
		padding-bottom: 1em;
	}

.tablet({
	h1,h2,h3,h4,h5,h6{
		font-size: 4rem;
	}
});

.desktop({
	
});

}