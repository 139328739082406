.block.faq{
	position: relative;
	padding: 15px;

	h1,h2,h3,h4,h5,h6{
		font-size: 3rem;
		padding-bottom: 10px;
	}

	p, ul, ol, dl{
		padding-bottom: 1em;
	}



	a{
		display: block;
		padding: 15px;
		font-size: 1.3rem;
		color: @heading;

		&:hover{
			background: @button-bg;
			color: #fff;
		}

		&.active{
			color: #fff;
			background: @text;
		}
	}

.tablet({
	.inner{
		display: flex;
	}

	nav{
		width: 25%;
		padding-right: 20px;
		border-right: 1px solid #ddd;
		margin-right: 30px;
	}



	.item{
		padding: 25px 0;

		&.active{
			display: block;
		}
	}

	article {
		width: 75%;
	}

	// .item + .item{
	// 	display: none;
	// }
});

.desktop({

});

}

#rs-builder {
	.item{

		+ .item{
			padding-top: 30px;
		}
	}
}