.block.fp-filter {
  box-sizing: border-box;
  *, *:before, *:after {
    box-sizing: inherit;
  }
  .inner {
    padding: 0 15px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #F6F6F6;
    border-radius: 15px;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, .1);
    padding: 25px 32px;
    max-width: 1000px;
    color: #6C6C6C;
    fieldset {
      flex-basis: 100%;
      border: 0;
      legend {
        opacity: 0;
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        margin: -1px;
        padding: 0;
        border: 0;
      }
    }
    label {
      font-weight: normal;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        fill: currentColor;
      }
    }
    select {
      background-color: #E4E4E4;
      text-indent: 5px;
      height: 4rem;
      option {
        font-size: 1.6rem;
      }
    }
  }


  .desktop({
    form {
      flex-direction: row;
      fieldset {
        flex-basis: 25%;
        border: 0;
      }
    }
  })
}


.block.doubleup + .block.fp-filter {
  form {
    margin-top: -35px;
    margin-bottom: 25px;
  }

  .tablet({
    form {
      margin-top: -80px;
    }
  })
}