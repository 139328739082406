.block.frame-image{
	position: relative;

	article{
		margin: -80px 20px 0;
		padding: 70px 40px;
		text-align: center;
		background: #fff;
		position: relative;

		&:before{
			position: absolute;
			top: 19px;
			left:19px;
			bottom:19px;
			right:19px;
			content:"";
			border: 1px solid @secondary-bg;
			pointer-events: none;
		}
	}

	h1,h2,h3,h4,h5,h6{
		color: @secondary-bg;
		strong{
			display: block;
			font-weight: normal;
			font-size: .85em;
			opacity: .8;
		}
	}

	p{
		padding: 0 0 2em;
	}

	a{
		.button;
	}

.tablet({
	article{
		position: absolute;
		bottom:0;
		right:0;
		width: 550px;
		text-align: left;
		padding-left: 75px;
	}

	h1,h2,h3,h4,h5,h6{
		strong{
			font-size: 2.2rem;
			font-weight: bold;
		}

		font-size: 5.5rem;
		font-weight: 700;
		line-height: 1;
	}
});

.desktop({

});

}