.block.gallery{
	position: relative;
	padding: 15px 0;

	ul{
		list-style: none;
	}

	li{
		padding: 15px;
	}

.tablet({

	ul{
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	li{
		padding:3px;
		width: 25%;
		height: 23vw;
		box-sizing: border-box;

		a{
			display: block;
			height: 100%;
			width: 100%;
			overflow: hidden;

			&:hover{
				img{
					min-height: 105%;
					margin-top: -2.5%;
				}
			}
		}

		img{
			display: block;
			object-fit: cover;
			min-height: 100%;
			transition: ease all .3s;
		}
	}
});

.desktop({
	
});

}