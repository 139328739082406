.block.doubleup{
	position: relative;
	background: #060606 url(/assets/img/marbel.jpg) center;
	padding: 45px 15px;
	color: #fff;
	margin: 15px;
	text-align: center;

	h1,h2,h3,h4,h5,h6{
		font-size: 2.2rem;
	}

.tablet({
	padding: 90px 15px;
	h1,h2,h3,h4,h5,h6{
		font-size: 4.5rem;
	}
	
});

.desktop({
	
});

}