.block.image-feature{
	position: relative;
	background: #fff;
	article{
		margin: -80px 20px 0;
		padding: 70px 40px;
		text-align: center;
		background: #fff;
		position: relative;

		&:before{
			position: absolute;
			top: 19px;
			left:19px;
			bottom:19px;
			right:19px;
			content:"";
			border: 1px solid @secondary-bg;
			pointer-events: none;
		}
	}

	h1,h2,h3,h4,h5,h6{
		color: @secondary-bg;
		strong{
			display: block;
			font-weight: normal;
			font-size: .85em;
			opacity: .8;
		}
	}

	p{
		padding: 0 0 2em;
	}

	a{
		.button;
	}

.tablet({
	.inner{
		display: flex;
	}

	figure{
		flex: 0 0 50%;
		width: 50%;
		display: flex;
		align-items: center;

		img{
			display: block;
		}
	}

	article{
		flex: 1 1 50%;
		width: 50%;
		display: flex;
		align-items: center;
		box-sizing: content-box;
		margin-top: 0;

		&:before{
			position: absolute;
			top: 19px;
			left:0px;
			bottom:19px;
			right:0px;
			content:"";
			border: 1px solid @secondary-bg;
			padding: 0 75px;
		}
	}

	h1,h2,h3,h4,h5,h6{
		strong{
			font-size: 2.2rem;
			font-weight: bold;
		}

		font-size: 5.5rem;
		font-weight: 700;
		line-height: 1;
	}

	&.frame{
		margin: 100px auto;
		max-width: 1200px;
		position: relative;

		figure{
			flex: 1 1 66%;
			width: 66%;
			> div{
				width: 50%;
				height: 100%;
				position: relative;
				overflow: hidden;
			}

			img{
				position: relative;
				object-fit: cover;
				min-height: 100%;
			}
		}

		article{
			flex: 1 1 32%;
			width: 34%;
		}
	}

	&.lshape{
		.inner{
		}
		figure{
			img{
				position: relative;
				object-fit: cover;
				height: 100%;
				width: auto;
				min-width: 100%;
			}
		}

		article{
			flex: 1 1 50%;
			padding-bottom: 50%;
			margin-top: 0;
			box-sizing: content-box;

			&:before{
				bottom: 52%;
			}
		}

		article + figure{
			height: 50%;
			position: absolute;
			bottom:0;
			right:0;
			width: 50%;

			img{
				position: relative;
				object-fit: cover;
				min-height: 100%;
			}
		}
	}
});

.desktop({

});

}