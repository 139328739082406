.block.contact{
	position: relative;
	padding: 15px;

	.module{
		position: relative;
		padding: 15px;
		background: #ddd;
		border-bottom: 5px solid @button-bg;
		margin-bottom: 20px;
		a {
			color: @text;
			&:hover, &:focus {
				color: @text-link;
				text-decoration: underline;
			}
		}
	}

	p + p{
		padding-top: 1em;
	}

	.field > button{
		display: none;
	}

.tablet({
	.inner{
		display: flex;
	}

	aside{
		width: 25%;
		padding-right: 40px;
	}
});

.desktop({

});

}