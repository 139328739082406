.block.blogs, .block.blogs.community-highlights{
	position: relative;
	padding: 15px;

	.item{
		position: relative;
		background: #000;
	}

	article{
		padding: 25px 15px 45px;
		color: #fff;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.item{
		width: 49%;
		margin-bottom: 25px;

		&:first-of-type{
			width: 60%;
		}

		&:nth-of-type(2){
			width: 38%;
		}
	}

	figure{
		height: 420px;
		overflow: hidden;
		img{
			min-height: 100%;
			min-width: 100%;
			width: auto;
			object-fit: cover;
			max-height: 140%;
		}
	}
});

.desktop({

});

}


.block.blogs-list{
	position: relative;
	padding: 15px;

	.swiper {
		width: 100%;
		display: flex;
		position: relative;
		user-select: none;
		.swiper-wrapper {
			display: flex;
		}
	}

	.blog-card{
		position: relative;
		background: #000;
	}

	figure {
		position: relative;
		max-height: 420px;
		overflow: hidden;
		h2 {
			position: absolute;
			bottom: 20px;
			left: 0;
			padding: 10px;
			width: 100%;
			max-width: fit-content;
			background-color: #000;
			color: #fff;
		}
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	article{
		padding: 25px 15px 45px;
		color: #fff;
		.body {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			-webkit-line-clamp: 1;
			text-overflow: ellipsis;
			max-height: 1.5em;
			line-height: 1.5;
			margin-bottom: 30px;
		}
		a {
			color: #fff;
			text-decoration: underline;
			&:hover, &:focus {
				opacity: 0.6;
			}
		}
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.blog-card{
		width: 49%;
		margin-bottom: 25px;

		&:first-of-type{
			// width: 60%;
		}

		&:nth-of-type(2){
			// width: 38%;
		}
	}

	figure{
		height: 420px;
		overflow: hidden;

		img{
			min-height: 100%;
			min-width: 100%;
			width: auto;
			object-fit: cover;
			max-height: 140%;
		}
	}
});

.desktop({

});

}


article.text-content {
	position: relative;
	padding: 35px 15px;
	background-color: #fff;
	.inner{
		max-width: 1370px;
	}
	.body, .additional-blogs {
		padding: 0 15px;
	}
	.body {
		margin-bottom: 35px;
	}

	.prev-next-buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		a {
			position: relative;
			display: inline-block;
			line-height: 24px;
			padding: 16px 32px;
			background: @button-bg;
			color: @button-text;
			font-size: 1.5rem;
			cursor: pointer;
			border-radius: 1px;
			transition: ease background-color .3s;
			text-align: center;
			margin: 0;
			border-radius: 5px;
			&:hover, &:focus {
				text-decoration: none;
				background-color: color-mix(in srgb, #000 45%, @button-bg);
			}
		}
		.disabled {
			opacity: 0.5;
			cursor: not-allowed;
			&:hover, &:focus {
				text-decoration: none;
				background-color: @button-bg;
			}
		}
	}

	.additional-blogs {
		.additional-wrapper {
			background-color: #eee;
			margin-bottom: 15px;
			padding: 15px;
			border-bottom: 4px solid @button-bg;
			ul {
				margin-left: 20px;
				li {
					margin-bottom: 8px;
				}
			}
		}
	}

	h3, h4, h5, h6 {
		.h2;
	}

	p {
		margin-bottom: 10px;
		font-size: 1.6rem;
	}

	a {
		color: #1779ba;
		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	.tablet({
		.inner {
			display: flex;
		}
		.body {
			flex: 2;
		}
		.additional-blogs {
			flex: 1;
			h2 {
				font-size: 4.0rem;
			}
		}
	});

}


#rs-builder {
	.block.blogs-list {
		.blog-card:nth-child(n+4){
			display: none;
		}
	}
}