.block.locations {
  box-sizing: border-box;
  *, *:before, *:after {
    box-sizing: inherit;
  }
  padding-bottom: 75px;
  #location-map {
    height: 450px;
    width: 100%;
  }

  .info-window {
    h3 {
      .fontSize(1.9, 3.0, 3.0);
      margin-bottom: 10px;
    }
    h4 {
      .fontSize(1.8, 2.5, 2.5);
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      max-width: 300px;
      height: auto;
      margin-bottom: 10px;
    }
    address {
      * {
        .fontSize(1.4, 1.6, 1.6);
      }
    }
    a[href^="tel:"] {
      display: block;
      margin-top: 10px;
      .fontSize(1.6, 1.8, 1.8);
    }
  }

  .leasing-office::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNy4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjUgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTMxNi45IDE4QzMxMS42IDcgMzAwLjQgMCAyODguMSAwcy0yMy40IDctMjguOCAxOEwxOTUgMTUwLjMgNTEuNCAxNzEuNWMtMTIgMS44LTIyIDEwLjItMjUuNyAyMS43cy0uNyAyNC4yIDcuOSAzMi43TDEzNy44IDMyOSAxMTMuMiA0NzQuN2MtMiAxMiAzIDI0LjIgMTIuOSAzMS4zczIzIDggMzMuOCAyLjNsMTI4LjMtNjguNSAxMjguMyA2OC41YzEwLjggNS43IDIzLjkgNC45IDMzLjgtMi4zczE0LjktMTkuMyAxMi45LTMxLjNMNDM4LjUgMzI5IDU0Mi43IDIyNS45YzguNi04LjUgMTEuNy0yMS4yIDcuOS0zMi43cy0xMy43LTE5LjktMjUuNy0yMS43TDM4MS4yIDE1MC4zIDMxNi45IDE4eiIvPjwvc3ZnPg==") center left no-repeat;
    margin-right: 10px;
  }

  .leasing-office .category {
    margin-left: 0.5ch;
  }

  .locations-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    list-style: none;
    margin-top: 20px;
  }

  .location-item {
    width: 80%;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.8);

    .item-bar {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px 15px;
      h3 {
        color: #ffffff;
        font-size: 2.4rem;
        display: flex;
        align-items: center;
      }
    }

    .item-content {
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      .col {
        flex-basis: 100%;
        margin-bottom: 15px;
      }

      a, p {
        font-size: 1.6rem;
        color: @text;
      }
      a:hover, a:focus {
        text-decoration: underline;
      }

      h4 {
        color: @heading;
        margin-bottom: 10px;
        font-size: 2.4rem;
      }
      button.col {
        padding: 0;
        border: 0;
        background: none;
        cursor: pointer;
        height: fit-content;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          min-height: 137px;
          max-height: 300px;
          object-fit: contain;
        }
      }
      a.button {
        color: @button-text;
        margin-top: 10px;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }

  }

  .desktop({
    .location-item {
      width: 80%;
      margin: 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.8);

      .item-bar {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 10px 15px;
        * {
          color: #ffffff;
          font-size: 2.4rem;
        }
      }

      .item-content {
        justify-content: space-between;
        .col {
          flex-basis: 23%;
          margin-bottom: 0;
        }
        button.col {
          margin: auto 0;
        }
      }
    }
  });
}