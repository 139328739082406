.block.simple-text{
	position: relative;
	padding: 15px;

	.inner{
		max-width: 900px;
	}

.tablet({
	

});

.desktop({
	
});

}