.block.sub-hero{
	position: relative;
	height: 35vh;
	display: flex;
	box-sizing: content-box;
	padding: 0 25px;
	text-align: center;
	color: #fff;
	text-shadow: 0 0 5px rgba(0,0,0,.3);


	&:before{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right: 0;
		content:"";
		background: rgba(0,0,0,.5);
	}


	p{
		padding-top: 20px;
	}

	a{
		.button;
	}

.tablet({
	height: 50vh;

	h1,h2,h3,h4,h5,h6{
		font-size: 5.5rem;
	}
});

.desktop({
	
});

}