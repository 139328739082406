.block.connect-map{
	position: relative;
	background: #fff;

	article{
		margin: -80px 20px 0;
		padding: 70px 40px;
		text-align: left;
		background: #fff;
		position: relative;

		> div{
			position: relative;
		}

		&:before{
			position: absolute;
			top: 19px;
			left:19px;
			bottom:19px;
			right:19px;
			content:"";
			border: 1px solid @secondary-bg; 
		}
	}

	h1,h2,h3,h4,h5,h6{
		color: @secondary-bg;
        text-align: center;
		strong{
			display: block;
			font-weight: normal;
			font-size: .85em;
			opacity: .8;
		}
	}

	p{
		padding: 0 0 2em;
	}

	a{
		.button;
	}

	.form-group,
	.field{
		padding-bottom: 15px;

		> button{
			display: none;
		}
	}

.tablet({
	.inner{
		display: flex;
	}

	figure{
		flex: 0 0 50%;
		width: 50%;
		display: flex;
		align-items: center;

		img{
			display: block;
		}
	}

	article{
		flex: 1 1 50%;
		width: 50%;
		display: flex;
		align-items: center;
		box-sizing: content-box;
		margin-top: 0;

		&:before{
			position: absolute;
			top: 19px;
			left:0px;
			bottom:19px;
			right:0px;
			content:"";
			border: 1px solid @secondary-bg; 
			padding: 0 75px;
		}
	}

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		strong{
			font-size: 2.2rem;
			font-weight: bold;
		}

		font-size: 5.5rem;
		font-weight: 700;
		line-height: 1;
	}
});

.desktop({
	
});

}