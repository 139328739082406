html {
	font-size: 62.5%;
}

body{
	font-size: 1.7rem;
	line-height: 1.75;
	color: @text;
	font-family: @main;
	background: #eee;
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: 100%;
	width: 100%;
}

#cookie-box {
	z-index: 999999 !important;
}

@media (max-width: 767px){
	.no-mobile{
		display: none;
	}
	.no-desktop{
		display: block;
	}
}

@media (min-width: 768px){
	.no-mobile{
		display: block;
	}
	.no-desktop{
		display: none;
	}
}

.slbElement {
	z-index: 999999;
  position: fixed;
}

.slbContentOuter {
	.slbImage {
	  padding: 3em 0 5em !important;
	}
	button {
	  box-shadow: none !important;
	  font-weight: 300 !important;
	  background-color: transparent !important;
		font-size: 18px;
	}
	.slbCloseBtn {
	  right: 15px !important;
	  top: -10px !important;
	  height: unset !important;
	  width: unset !important;
		font-size: 50px !important;
	}
	.slbCaption {
	  // height: 4em;
	  font-size: 16px;
	  bottom: 10px !important;
	  overflow: hidden;
	  display: flex;
	  justify-content: space-between;
	}

	.slbCaption-text {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  text-align: left;
	  * {
		font-size: 16px;
	  }
	}
	.slbCaption-counter {
	  height: 4em;
	  font-size: 16px;
	}
  }