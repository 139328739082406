.block.features{
	position: relative;
	padding: 15px;
    max-width: 1400px !important;
    margin: 0 auto;
    article{
        background: #060606 url(/assets/img/marbel.jpg) center;
        color: #fff;
        padding: 45px 25px;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    li{
        list-style: none;
        padding-left: 26px;

        &:before{
            content: "";
            float: left;
            margin-left: -26px;
            height: 18px;
            width: 18px;
            margin-top: 4px;
            background: url(/assets/img/a-check.svg) center left no-repeat;
        }

        > *{
            display: inline;
        }
    }

.tablet({
	h1,h2,h3,h4,h5,h6{
		font-size: 4.5rem;
	}

	article{
		display: flex;
		align-items: center;
		padding: 75px;
	}

	aside{
		width: 50%;
	}
});

.desktop({

});

}