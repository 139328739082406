.block.hero{
	position: relative;
	height: 100vh;
	display: flex;
	box-sizing: content-box;
	padding: 0 25px;
	color: #fff;
	font-size: 2rem;


	h1,h2,h3,h4,h5,h6{
		font-size: 5.5rem;
		font-weight: 700;
	}

	&:before{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right: 0;
		content:"";
		background: rgba(0,0,0,.5);
	}

	p{
		padding-top: 20px;
	}

	a{
		.button;
		
		
	}

	a + a{
		.button;
		.light;
	}

.tablet({
	
});

.desktop({
	
});

}