.block.breadcrumbs{
	position: relative;
	background: #060606;
	padding: 15px;
	text-transform: uppercase;
	font-size: 1.4rem;
	color: #fff;
	border-top: 1px solid @button-bg;
	border-bottom: 8px solid @button-bg;

	.inner {
		max-width: 1370px;
	}

	li{
		display: inline;

		&:after{
			content: " / ";
			padding: 0 7px;
		}

		&:last-of-type{
			a{
				text-decoration: none;
			}

			&:after{
				content:"";
			}
		}
	}

	a{
		text-decoration: underline;
		color: #fff;
	}

.tablet({
	padding: 32px 15px;
});

.desktop({

});

}