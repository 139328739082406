.block.floorplan-slider{
	position: relative;
	padding: 15px;

    article{
        li{
            position: relative;
            border-radius: 20px;
            box-shadow: 0 0 10px rgba(0,0,0,.1);
            overflow: hidden;
            text-align: center;
			background-color: #fff;
            margin-bottom: 25px;
        }

        .empty {
            box-shadow: none;
            background: none;
            border: none;
        }
    }

    .tour{
        height: 50px;
        line-height: 50px;
        background: #060606;
        opacity: .8;
    }

    .image{
        height: 226px;
        padding: 20px 0;

        img{
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }

    h3{
        padding-bottom: 35px;
		font-size: 3rem;
        color: #333 !important;
    }

    h4{
		font-family: @main;
		padding-bottom: 25px;
        color: #333 !important;
        strong{
            font-size: 3.75rem;
            font-weight: normal;
            display: block;
        }

        em{
            font-weight: normal;
            font-style: normal;
            opacity: .7;
        }
    }

    .meta{
        height: 50px;
        border-top: 1px solid #eee;
        line-height: 50px;
        color: #333 !important;

        span{
            padding: 0 15px;
        }

        .beds{
            padding-left: 23px;
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNS4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTMyIDMyYzE3LjcgMCAzMiAxNC4zIDMyIDMyVjMyMEgyODhWMTYwYzAtMTcuNyAxNC4zLTMyIDMyLTMySDU0NGM1MyAwIDk2IDQzIDk2IDk2VjQ0OGMwIDE3LjctMTQuMyAzMi0zMiAzMnMtMzItMTQuMy0zMi0zMlY0MTZIMzUyIDMyMCA2NHYzMmMwIDE3LjctMTQuMyAzMi0zMiAzMnMtMzItMTQuMy0zMi0zMlY2NEMwIDQ2LjMgMTQuMyAzMiAzMiAzMnptMTQ0IDk2YTgwIDgwIDAgMSAxIDAgMTYwIDgwIDgwIDAgMSAxIDAtMTYweiIvPjwvc3ZnPg==") center left no-repeat;
            background-size: 14px 14px;
        }

        .baths{
            padding-left: 23px;
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNS4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZD0iTTk2IDc3LjNjMC03LjMgNS45LTEzLjMgMTMuMy0xMy4zYzMuNSAwIDYuOSAxLjQgOS40IDMuOWwxNC45IDE0LjlDMTMwIDkxLjggMTI4IDEwMS43IDEyOCAxMTJjMCAxOS45IDcuMiAzOCAxOS4yIDUyYy01LjMgOS4yLTQgMjEuMSAzLjggMjljOS40IDkuNCAyNC42IDkuNCAzMy45IDBMMjg5IDg5YzkuNC05LjQgOS40LTI0LjYgMC0zMy45Yy03LjktNy45LTE5LjgtOS4xLTI5LTMuOEMyNDYgMzkuMiAyMjcuOSAzMiAyMDggMzJjLTEwLjMgMC0yMC4yIDItMjkuMiA1LjVMMTYzLjkgMjIuNkMxNDkuNCA4LjEgMTI5LjcgMCAxMDkuMyAwQzY2LjYgMCAzMiAzNC42IDMyIDc3LjNWMjU2Yy0xNy43IDAtMzIgMTQuMy0zMiAzMnMxNC4zIDMyIDMyIDMySDQ4MGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg5NlY3Ny4zek0zMiAzNTJ2MTZjMCAyOC40IDEyLjQgNTQgMzIgNzEuNlY0ODBjMCAxNy43IDE0LjMgMzIgMzIgMzJzMzItMTQuMyAzMi0zMlY0NjRIMzg0djE2YzAgMTcuNyAxNC4zIDMyIDMyIDMyczMyLTE0LjMgMzItMzJWNDM5LjZjMTkuNi0xNy42IDMyLTQzLjEgMzItNzEuNlYzNTJIMzJ6Ii8+PC9zdmc+") center left no-repeat;
            background-size: 14px 14px;
        }
    }

    .status{
        height: 50px;
        line-height: 50px;
        background: #363636;
        display: block;
        color: #fff;
        font-weight: bold;
    }

    nav{
        padding: 50px 0 30px;
        text-align: center;

        p{
            padding-top: 8px;
            line-height: 1.3;
			font-style: italic;
			font-size: 1.2rem;
        }

        li{
            line-height: 42px;
            display: inline-block;
            padding: 4px 10px;
        }
        a{
            background: @heading-alt;
            height: 42px;
            width: 42px;
            text-align: center;
            display: block;
            color: #fff;
            font-weight: bold;

			&:hover{
				opacity: .8;
			}
        }
    }

.tablet({
    ul{
        display: flex;
        justify-content: center;
        margin: auto;
        max-width: 1370px;
        flex-wrap: wrap;
    }

    article{
        li{
            width: 32%;
            margin: .5%;
        }

        a{
            color: @heading-color !important;
        }
    }
});

.desktop({

});

}