@import '../base/_utilities';

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// universal
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus-visible {
		outline: @button-bg auto 3px;
	}
}

img{
	max-width: 100%;
}


// links
a {
	text-decoration: none;
	transition: ease all .3s;
	outline: none;
	color: @text-link;
	cursor: pointer;

	&:hover, &:focus {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}


// headings
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	text-rendering: optimizelegibility;
	margin: 0;
	font-weight: 700;
	line-height: 1.3;
	font-family: @heading;
}

h1, .h1 {
	.fontSize(2.5,2.5,2.5);
}

h2, .h2 {
	.fontSize(2.5,2.5,2.5);
	padding-bottom: 15px;
}

button, .button, a.button{
	position: relative;
	display: inline-block;
	line-height: 24px;
	padding: 16px 32px;
	background: @button-bg;
	color: @button-text;
	font-size: 1.5rem;
	cursor: pointer;
	// font-weight: bold;
	border-radius: 1px;
	transition: ease all .3s;
	text-align: center;
	margin: auto;
	border-radius: 5px;

	&.ghost{
		background: none;
		color: @button-bg;
		border:0;
	}

	&.light{
		background: @button-text;
		color: @button-bg;
		border:0;
	}

	&.inline{
		display: inline-block;
	}

	&:hover, &:focus{
		// color: @text;
		background: @button-bg-hover;
	}

	& + .button, & +button, & +a.button{
		background: @button-text;
		color: @button-bg;
		&:hover, &:focus {
			color: @button-text;
			background: @button-bg;
		}
	}
}

.outline{
	background: none;
	border: 2px solid blue;
	color: #000;
}

p {
	margin: 0;
}

ul, ol {
	margin: 0;
	padding:0;
}

p, ul, ol, dl {
	// + p, + ul, + ol, + dl {
	// 	margin-top: 1.5em;
	// }
}

nav, .nav, nav ul, .nav ul {
	list-style-type: none;
	padding-left: 0;
}

hr {
	border:none;
	background:#eee;
	height:2px;
	width:60px;
	margin:35px auto 0;
}

.alignleft, .alignright {
	margin-bottom: 1em;
}

address {
	font-style: normal;
}

.center {
	text-align: center;
}

.upper {
	text-transform: uppercase;
}

.screen-reader-text {
	position: absolute !important;
	left: -999em;
}

.html-times {
	font-size: 1.5em;
	vertical-align: sub;
}