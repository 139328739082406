.block.text-page {
  padding: 20px 15px;

  h2, h3 ,h4 ,h5, h6 {
    margin: 10px 0 20px;
    font-size: 3.9rem;
  }

  p {
    margin-bottom: 10px;
  }

  a {
    color: #334EFF;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  ol, ul {
    list-style: none;
  }
}