#topbar{
	position: fixed;
	top:0;
	left:0;
	right:0;
	padding: 15px;
	height: fit-content;
	background: rgba(0,0,0,.6);
	z-index: 1000;
	transition: ease all .3s;

	.logo{
		// height: 48px;
		width: auto;
		max-width: 80px;
		display: flex;
		align-items: center;
		img {
			max-height: 110px;
		}
	}

	li{
		list-style: none;
	}

	a{
		color: #fff;
		font-weight: bold;
	}

	.inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.topbar-menu{
		display: flex;
		height: fit-content;
		align-items: center;
	}

	// @media screen and (max-width: 768px){
	// 	.topbar-menu ul{
	// 		display: none;
	// 	}
	// }

	#grip{
		height: 48px;
		width: 48px;
		margin-left: 10px;
		background: @button-bg;
		color: #fff;
		display: flex;
		align-items: center;
		text-align: center;

		svg{
			height: 25px;
			width: 25px;
			margin: auto;
		}
	}

.tablet({
	background: none;

	&.scrolled{
		background: rgba(0,0,0,.6);
	}

	.logo{
		// height: 48px;
		// width: 11.875rem;
		max-width: 190px;
	}

	&.scrolled{
		background: rgba(0,0,0,.6);
		.topbar-menu{
			background: none;
		}
	}
	.topbar-menu{
		border-bottom: 1px solid @button-bg;
		background: rgba(0,0,0,.8);
		transition: ease all .3s;
	}

	#grip{
		margin-left: 0;
	}

	ul{
		display: flex;
		height: 48px;

		a{
			height: 48px;
			line-height: 48px;
			display: block;
			font-weight: normal;
			padding: 0 25px;

			&:hover{
				color: #fff;
				background: @button-bg;
			}
		}
	}
});

.desktop({

});

}