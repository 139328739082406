.block.double-feature{
	position: relative;
	padding: 15px;

	a{
		color: #fff !important;
	}

	.item{
		position: relative;
		display: block;
	}

	img{
		display: block;
		transition: ease all .3s;
	}

	.item a:hover{
		.label{
			background: #060606;
		}

		img{
			opacity: .7;
		}
	}

	.label{
		position: absolute;
		bottom: 0;
		right:0;
		line-height: 20px;
		padding: 15px;
		background: @heading-color;
		color: #fff !important;
		transition: ease all .3s;
	}

	.item:hover label{
		opacity: .7;
	}

	.item + .item{
		.label{
			background: @button-bg;
		}
	}

.tablet({
	.inner{
		display: flex;
	}

	.label{
		padding: 30px 35px;
	}

	.item{
		// width: 33.333%;

		a{
			display: block;
			height: 100%;
		}

		img{
			min-height: 100%;
			min-width: 100%;
			object-fit: cover;
		}
	}
});

.desktop({
	
});

}